<template>
  <v-card flat>
    <table class="score">
      <colgroup>
        <col style="width:11%" />
        <col style="width:11%" />
        <col style="width:11%" />
        <col style="width:14%" />
        <col />
        <col style="width:14%" />
        <col style="width:11%" />
        <col style="width:11%" />
        <col style="width:11%" />
      </colgroup>
      <tr class="head">
        <td colspan="4" class="heim">{{ h.team.name }}</td>
        <td></td>
        <td colspan="4" class="gast">{{ g.team.name }}</td>
      </tr>
      <template v-for="(r, i) in table">
        <tr :key="i + '-' + 1" :class="{ even: i % 2 === 0 }">
          <td colspan="3" class="heim name">{{ r.h.person | person }}</td>
          <td class="heim endnote">{{ r.h.result.score }}</td>
          <td></td>
          <td class="endnote">{{ r.g.result.score }}</td>
          <td colspan="3" class="name">{{ r.g.result.person | person }}</td>
        </tr>
        <tr :key="i + '-' + 2" :class="{ even: i % 2 === 0 }" v-if="r.h.result.score !== null && r.g.result.score !== null">
          <td colspan="1" :class="{heim: true, hidden: !r.h.result.penalty}">Pen: {{ r.h.result.penalty | float1 }}</td>
          <td colspan="1" class="heim">E: {{ r.h.result.escore | float2 }}</td>
          <td colspan="1" class="heim">D: {{ r.h.result.dscore | float2 }}</td>
          <td colspan="1" class="heim">({{ r.h.result.final | float2 }})</td>
          <td></td>
          <td colspan="1">({{ r.g.result.final | float2 }})</td>
          <td colspan="1">D: {{ r.g.result.dscore | float2 }}</td>
          <td colspan="1">E: {{ r.g.result.escore | float2 }}</td>
          <td colspan="1" :class="{hidden: !r.g.result.penalty}">Pen: {{ r.g.result.penalty | float1 }}</td>
        </tr>
      </template>
      <tr>
        <td colspan="9">&nbsp;</td>
      </tr>
      <tr :class="{ergebnis:true, even: max % 2 === 1}">
        <td colspan="3" class="heim">Ergebnis {{ geraetname }}</td>
        <td class="heim ergebnis">{{ mannschaftgeraetergebnisscore(e, r, h.team._id, did) | int_0 }}</td>
        <td></td>
        <td class="ergebnis">{{ mannschaftgeraetergebnisscore(e, r, g.team._id, did) | int_0 }}</td>
        <td colspan="3">Ergebnis {{ geraetname }}</td>
      </tr>
      <tr :class="{even: max % 2 === 1}">
        <td colspan="3" class="heim"></td>
        <td class="heim">({{ mannschaftgeraetergebnis(e, r, h.team._id, did) | float2_0 }})</td>
        <td></td>
        <td>({{ mannschaftgeraetergebnis(e, r, g.team._id, did) | float2_0 }})</td>
        <td colspan="3"></td>
      </tr>
      <tr :class="{ergebnis:true, even: max % 2 === 0}">
        <td colspan="3" class="heim">Ergebnis gesamt</td>
        <td class="heim ergebnis">{{ mannschaftgesamtergebnisscore(e, r, h.team._id) | int_0 }}</td>
        <td></td>
        <td class="ergebnis">{{ mannschaftgesamtergebnisscore(e, r, g.team._id) | int_0 }}</td>
        <td colspan="3">Ergebnis gesamt</td>
      </tr>
      <tr :class="{even: max % 2 === 0}">
        <td colspan="3" class="heim"></td>
        <td class="heim">({{ mannschaftgesamtergebnis(e, r, h.team._id, did) | float2_0 }})</td>
        <td></td>
        <td>({{ mannschaftgesamtergebnis(e, r, g.team._id, did) | float2_0 }})</td>
        <td colspan="3"></td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import { useCalc } from '../../../plugins/calc'

export default {
  name: 'wk_score4',

  setup (props, context) {
    return {
      ...useCalc(props, context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    did: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    h () {
      const heim = this.heim(this.e)
      const turner = this.mannschaftturnerangeraet(this.e, this.r, heim?.team?._id, this.did)

      return {
        team: heim.team,
        athletes: turner
      }
    },
    g () {
      const gast = this.gast(this.e)
      const turner = this.mannschaftturnerangeraet(this.e, this.r, gast?.team?._id, this.did)

      return {
        team: gast.team,
        athletes: turner
      }
    },
    max () {
      return Math.max(this.h.athletes.length, this.g.athletes.length)
    },
    geraetname () {
      return this.geraet(this.df, this.did)?.name || ''
    },
    table () {
      const rows = []

      for (let i = 0; i < this.max; i++) {
        rows.push({
          h: this.h.athletes[i] || { result: {} },
          g: this.g.athletes[i] || { result: {} }
        })
      }

      return rows
    }
  }
}
</script>

<style scoped>

</style>
